/* ========================================================================

 * DOM-based Routing

 * Based on http://goo.gl/EUTi53 by Paul Irish

 *

 * Only fires on body classes that match. If a body class contains a dash,

 * replace the dash with an underscore when adding it to the object below.

 *

 * .noConflict()

 * The routing is enclosed within an anonymous function so that you can

 * always reference jQuery with $, even when in .noConflict() mode.

 * ======================================================================== */



(function($) {



  // Use this variable to set up the common and page specific functions. If you

  // rename this variable, you will also need to rename the namespace below.

  var Sage = {

    // All pages

    'common': {

      init: function() {

        var formEl = jQuery('#mc-embedded-subscribe-form');
  
        function register($form) {
          $.ajax({
              type: formEl.attr('method'),
              url: formEl.attr('action'),
              data: formEl.serialize(),
              cache       : false,
              dataType    : 'json',
              contentType: "application/json; charset=utf-8",
              error       : function(err) { alert("Could not connect to the registration server. Please try again later."); },
              success     : function(data) {
                  if (data.result != "success") {
                      // Something went wrong, do something to notify the user. maybe alert(data.msg);
                      console.log(data);
                      $('#mc-result').html(emailExists);
                      $('#mc-result').show();
                  } else {
                      // It worked, carry on...
                      console.log(data);
                      $('#mc-result').html(emailSuccess);
                      $('#mc-result').show();
                  }
              }
          });
        }

        if($('html').attr('lang') == 'pt-PT'){
          var emailMsg = 'Por favor insira o seu email.';
          var agreeMsg = 'Por favor confirme que concorda com os nossos termos e condições.';
          var emailExists = 'O endereço de email que inseriu já está na nossa base de dados.';
          var emailSuccess = 'Obrigado por subscrever a nossa newsletter!';
        } else {
          var emailMsg = 'Please insert your email address.';
          var agreeMsg = 'Please confirm that you agree to our terms and conditions.';
          var emailExists = 'The email address you inserted is already on our database.';
          var emailSuccess = 'Thank you for subscribing to our newsletter!';
        } 
      
        $('#mc-embedded-subscribe-form').validate({
          rules : {
            EMAIL : 'required',
            gdpr_accept : 'required',
          },
          messages: {
            EMAIL : emailMsg,
            gdpr_accept : agreeMsg,
          },
          errorPlacement: function(error, element) {
            if ( element.attr("name") == "gdpr_accept" ){
                error.appendTo("#checkbox-errorbox");
            } else {
              error.insertAfter(element);
            };
        }
        })
      
        $(document).on('submit', '#mc-embedded-subscribe-form', function(event) {
          if($('#mc-embedded-subscribe-form').valid()){
            try {
              var $form = $(this);
              event.preventDefault();
              register($form);
            } catch(error){}
          }    
        });

        // JavaScript to be fired on all pages

        if($('#related-items').length > 0 ){

          if($(window).width() < 769 ){

            $('.works-page-item').removeClass('col-md-4');

            $('.works-page-item').addClass('swiper-slide');



            $('#related-items > .container > .row').wrapInner('<div class="swiper-container"><div class="swiper-wrapper"></div></div>');

          }

        }

        if($(window).width() < 769 ){
          $('.menu-item-has-children a').on('click',function(){
            $(this).toggleClass('expanded');
            $(this).next('ul').slideToggle(400);
          })
        }





        //filter reference works by type, using menu on top

        $('a.item-filter').click(function(){

          if($(this).hasClass('active')){

            $('a.item-filter').removeClass('active');

          } else {

            $('a.item-filter').removeClass('active');

            $(this).addClass('active');

          }

          checkfilters();

        });



        $('#filter-all').click(function(){

          $('a.item-filter').removeClass('active');

          checkfilters();

        })



        function checkfilters(){

          var activeFilters = $('a.item-filter.active');



          if( activeFilters.length > 0 ){

            var activeToggle = activeFilters.attr('data-toggle');

            $('.filterable-item').hide();

            $('.filterable-item[data-filter*="'+activeToggle+'"]').show();

            $('#filter-all').removeClass('active');

          } else {

            $('.filterable-item').show();
            $('#filter-all').addClass('active');

          }

        }





      },

      finalize: function() {

        // JavaScript to be fired on all pages, after page specific JS is fired

        var relatedSwiper = new Swiper('#related-items .swiper-container', {

          noSwiping: false,

          slidesPerView: 1,

          autoplay: false,

          speed: 800,

          loop: true,

          pagination: {

            el: '.swiper-pagination',

            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {

              return '<span class="' + className + '"><span class="growing-line"><span class="growing-inner"></span></span><span class="pag-content"><span class="pag-inner-number">0' + (index + 1) + '</span><span class="pag-inner-title">' + titles[index] + '</span></span></span>';

            }

          },    

          

          navigation: {

            nextEl: '#banner-wrap  .arrow-next',

            prevEl: '#banner-wrap  .arrow-prev',

          }



        });

      }

    },



    // Home page

    'home': {

      init: function() {

        // JavaScript to be fired on the home page
        var nwSwiper2 = new Swiper('#new-swiper-two', {

          autoplay: {

            delay: 5000,

          },

          noSwiping: true,
    
          slidesPerView: 3,
    
          speed: 800,
    
          loop: true,
    
          breakpoints: {
            2000: {
              slidesPerView: 3,
              noSwiping: true,
            },
            768: {
              slidesPerView: 1,
              noSwiping: true,
            },
    
          },
          pagination: {
            clickable: true,
            el: '.new-pagination-two',
    
          },    
    
          
    
        });

      },

      finalize: function() {

        // JavaScript to be fired on the home page, after the init JS

        //Banner Swiper

        var titles = [];

        $('#banner-slides .swiper-slide').each(function(i) {

          titles.push($(this).attr('data-title'));

        });



        var transitionTime = 5000;

        var bannerSwiper = new Swiper('#banner-slides', {

          noSwiping: false,

          autoplay: {

            delay: transitionTime,

          },

          speed: 800,

          loop: true,

          breakpoints: {

            768: {

              noSwiping: true,

            },

          },

          pagination: {

            el: '.swiper-pagination',

            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {

              return '<span class="' + className + '"><span class="growing-line"><span class="growing-inner"></span></span><span class="pag-content"><span class="pag-inner-number">0' + (index + 1) + '</span><span class="pag-inner-title">' + titles[index] + '</span></span></span>';

            }

          },    

          

          navigation: {

            nextEl: '#banner-wrap  .arrow-next',

            prevEl: '#banner-wrap  .arrow-prev',

          }



        });



        var bannerSwiper = new Swiper('#banner-slides-mobile', {

          noSwiping: false,

          autoplay: {

            delay: transitionTime,

          },

          speed: 800,

          loop: true,

          breakpoints: {

            768: {

              noSwiping: true,

            },

          },

          pagination: {
            clickable: true,
            el: '.swiper-pagination-mobile',

          },    

          

          navigation: {

            nextEl: '#banner-wrap  .arrow-next',

            prevEl: '#banner-wrap  .arrow-prev',

          }

        });



        /* $( '.swiper-pagination-bullet-active .growing-inner' ).animate({

          width: '100%'

        }, transitionTime, function() {

          // Animation complete.

          $( this ).css('width','0px');

        }); */

      }

    },

    // About us page, note the change from about-us to about_us.

    'about_us': {

      init: function() {

        // JavaScript to be fired on the about us page

      }

    },

    'single_reference_work': {

      init: function() {

        // JavaScript to be fired on the about us page

        console.log('single work');

      }

    }



    

  };


  // The routing fires all common scripts, followed by the page specific scripts.

  // Add additional events for more control over timing e.g. a finalize event

  var UTIL = {

    fire: function(func, funcname, args) {

      var fire;

      var namespace = Sage;

      funcname = (funcname === undefined) ? 'init' : funcname;

      fire = func !== '';

      fire = fire && namespace[func];

      fire = fire && typeof namespace[func][funcname] === 'function';



      if (fire) {

        namespace[func][funcname](args);

      }

    },

    loadEvents: function() {

      // Fire common init JS

      UTIL.fire('common');



      // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {

        UTIL.fire(classnm);

        UTIL.fire(classnm, 'finalize');

      });



      // Fire common finalize JS

      UTIL.fire('common', 'finalize');

    }

  };



  // Load Events

  $(document).ready(UTIL.loadEvents);





//Menu Mobile



  //Toggle Language Selector

  $('.burger-container').on('click',function(){

    $('.header').toggleClass('menu-opened');

  })

//Sticky Menu Services

jQuery(document).ready(function() {

  jQuery(window).scroll(function() {

        var distanceFromTop = jQuery(document).scrollTop();

        if (distanceFromTop >= jQuery('#contacts-bg').height())

        {

          jQuery('.menu-services').addClass('fixed');
          jQuery('body').addClass('menu-fixed');
        }

        else

        {

          jQuery('.menu-services').removeClass('fixed');
          jQuery('body').removeClass('menu-fixed');

        }

    });

});


  //Toggle Language Selector

  $('#lang-toggle-button').on('click',function(){

    $('ul.language-switcher').toggleClass('open');

  })



  $('.window .header').on('click',function(){

    $('body').toggleClass('over');

  })



  $('.search-btn-mobile').on('click',function(){

    $('.search-mobile-bar').toggleClass('no-top');

    $('.search-btn-mobile').toggleClass('hide');

    $('.fa-search-first').toggleClass('hide');

    $('body').removeClass('over');
    $('.window .header').removeClass('menu-opened');

  })



  $('img.close-search').on('click',function(){

    $('.search-mobile-bar').toggleClass('no-top');

    $('.fa-search-first').toggleClass('hide');

  })


  if ( $(window).width() > 739) {  

    

    $(document).ready(function () {

      $(".business-item").mouseenter(function(){

        $(".business-item").addClass("border");

        $(".business-item").removeClass("no-border");
        
        $(".photo-col").addClass("border");

        $(".photo-col").removeClass("no-border");

      });

      $(".business-item").mouseleave(function(){

        $(".business-item").removeClass("border");

        $(".business-item").addClass("no-border");

        $(".photo-col").removeClass("border");

        $(".photo-col").addClass("no-border");

      });

    });

  



    //Comercial

  $(document).ready(function () {

    $(".business-item.Comercial").mouseenter(function(){

      $(".Comercial .item-description").removeClass("hidden");

      $(".Industrial .item-inner").addClass("opac");

      $(".Edifícios .item-inner").addClass("opac");

      $(".Buildings .item-inner").addClass("opac");

      $(".Urbano .item-inner").addClass("opac");

      $(".Urban .item-inner").addClass("opac");

    });

    $(".business-item.Comercial").mouseleave(function(){

      $(".Comercial .item-description").addClass("hidden");

      $(".Industrial .item-inner").removeClass("opac");

      $(".Edifícios .item-inner").removeClass("opac");

      $(".Buildings .item-inner").removeClass("opac");

      $(".Urbano .item-inner").removeClass("opac");

      $(".Urban .item-inner").removeClass("opac");

    });

  });







  //Edifícios

  $(document).ready(function () {

    $(".business-item.Edifícios").mouseenter(function(){

      $(".Edifícios .item-description").removeClass("hidden");

      $(".Buildings .item-description").removeClass("hidden");

      $(".Industrial .item-inner").addClass("opac");

      $(".Comercial .item-inner").addClass("opac");

      $(".Urbano .item-inner").addClass("opac");

      $(".Urban .item-inner").addClass("opac");

    });

    $(".business-item.Edifícios").mouseleave(function(){

      $(".Edifícios .item-description").addClass("hidden");

      $(".Buildings .item-description").addClass("hidden");

      $(".Industrial .item-inner").removeClass("opac");

      $(".Comercial .item-inner").removeClass("opac");

      $(".Urbano .item-inner").removeClass("opac");

      $(".Urban .item-inner").removeClass("opac");

    });

  });



    //Buildings

    $(document).ready(function () {

      $(".business-item.Buildings").mouseenter(function(){

        $(".Buildings .item-description").removeClass("hidden");

        $(".Buildings .item-description").removeClass("hidden");

        $(".Industrial .item-inner").addClass("opac");

        $(".Comercial .item-inner").addClass("opac");

        $(".Urban .item-inner").addClass("opac");

      });

      $(".business-item.Buildings").mouseleave(function(){

        $(".Buildings .item-description").addClass("hidden");

        $(".Buildings .item-description").addClass("hidden");

        $(".Industrial .item-inner").removeClass("opac");

        $(".Comercial .item-inner").removeClass("opac");

        $(".Urban .item-inner").removeClass("opac");

      });

    });

    



  //Industrial

  $(document).ready(function () {

    $(".business-item.Industrial").mouseenter(function(){

      $(".Industrial .item-description").removeClass("hidden");

      $(".Edifícios .item-inner").addClass("opac");

      $(".Buildings .item-inner").addClass("opac");

      $(".Comercial .item-inner").addClass("opac");

      $(".Urbano .item-inner").addClass("opac");

      $(".Urban .item-inner").addClass("opac");

    });

    $(".business-item.Industrial").mouseleave(function(){

      $(".Industrial .item-description").addClass("hidden");

      $(".Edifícios .item-inner").removeClass("opac");

      $(".Buildings .item-inner").removeClass("opac");

      $(".Comercial .item-inner").removeClass("opac");

      $(".Urbano .item-inner").removeClass("opac");

      $(".Urban .item-inner").removeClass("opac");

    });

  });





  //Urbano

  $(document).ready(function () {

    $(".business-item.Urbano").mouseenter(function(){

      $(".Urbano .item-description").removeClass("hidden");

      $(".Urban .item-description").removeClass("hidden");

      $(".Edifícios .item-inner").addClass("opac");

      $(".Buidings .item-inner").addClass("opac");

      $(".Comercial .item-inner").addClass("opac");

      $(".Industrial .item-inner").addClass("opac");

    });

    $(".business-item.Urbano").mouseleave(function(){

      $(".Urbano .item-description").addClass("hidden");

      $(".Urban .item-description").addClass("hidden");

      $(".Edifícios .item-inner").removeClass("opac");

      $(".Buildings .item-inner").removeClass("opac");

      $(".Comercial .item-inner").removeClass("opac");

      $(".Industrial .item-inner").removeClass("opac");

    });

  });



    //Urban

    $(document).ready(function () {

      $(".business-item.Urban").mouseenter(function(){

        $(".Urban .item-description").removeClass("hidden");

        $(".Buildings .item-inner").addClass("opac");

        $(".Comercial .item-inner").addClass("opac");

        $(".Industrial .item-inner").addClass("opac");

      });

      $(".business-item.Urban").mouseleave(function(){

        $(".Urban .item-description").addClass("hidden");

        $(".Buildings .item-inner").removeClass("opac");

        $(".Comercial .item-inner").removeClass("opac");

        $(".Industrial .item-inner").removeClass("opac");

      });

    });



  //Set the Mouseover Actions on the Business Area Blocks

  $(document).on('mouseover', '.business-item', function(){

    $('.business-item').not(this).addClass('inactive');
   
    $('.areas-overlay').addClass('active');

    $(this).addClass('active');


    var activeItem = $(this).attr('data-order');

    $('.business-background-image').each(function(){

      if($(this).attr('data-order') == activeItem){

        $(this).addClass('active');

      } else {
        $(this).addClass('inactive');        

      }

    })

  });


  /*$(document).on('mouseout', '.business-item', function(){
    $(document).on('mouseover', '.business-item', function(){
      $('.areas-overlay').toggleClass('active');
    });
  });
*/

  $(document).on('mouseout', '.business-item', function(){
    $('.areas-overlay').removeClass('active');

    $('.business-item').addClass('inactive');

    $('.business-background-image').removeClass('inactive');

    $('.business-background-image').removeClass('active');

    //setTimeout(function(){
    //}, 200);

  });



  

  var testimonialSwiper = new Swiper('#testimonial-swiper', {

    autoplay: {

      delay: 5000,

    },

    noSwiping: false,

    slidesPerView: 2,

    speed: 800,

    loop: true,

    breakpoints: {

      768: {

        noSwiping: true,

      },

    },

    pagination: {
      clickable: true,
      el: '.testimonial-pagination',

    },    

    

  });



  var nwSwiper = new Swiper('#new-swiper', {

    noSwiping: false,

    slidesPerView: 3,

    speed: 800,

    loop: true,

    breakpoints: {

      768: {

        noSwiping: true,

      },

    },

    pagination: {
      clickable: true,

      el: '.new-pagination',

    },    

    
  });



  } 

  else {



    var nwSwiper = new Swiper('#new-swiper', {

      noSwiping: false,

      slidesPerView: 1,

      speed: 800,

      loop: true,

      breakpoints: {

        768: {

          noSwiping: true,

        },

      },

      pagination: {
        clickable: true,
        el: '.new-pagination',

      },    

      

    });


    if($(window).width() < 769 ){

      if($('body').hasClass('single-reference_work')){

        $('#related-wrap').wrapInner('<div id="related-swiper" class="swiper-container"><div class="swiper-wrapper"></div></div>');
        //$('#related-wrap .works-page-item').removeClass('col-md-4');
        $('#related-wrap .works-page-item').addClass('swiper-slide');

        var nwSwiper2 = new Swiper('#related-swiper', {
          noSwiping: false,
          slidesPerView: 1,
          speed: 800,
          loop: true,
          breakpoints: {
            768: {
              noSwiping: true,
            },
          },
          pagination: {
            clickable: true,
            el: '.new-pagination-two',
          },    
          
        });

      }

        
    }



    var testimonialSwiper = new Swiper('#testimonial-swiper', {

      noSwiping: false,

      slidesPerView: 1,

      speed: 800,

      loop: true,

      breakpoints: {

        768: {

          noSwiping: true,

        },

      },

      pagination: {
        clickable: true,
        el: '.testimonial-pagination',

      },    

      

    });

  

  }







    $(document).ready(function () {

      $(".tooltip-text").hide();

    $(".tooltip-item").mouseover(function(){
      $(".tooltip-text").hide();
      $(this).find(".tooltip-text").show();

    });

  });



  $(document).ready(function () {

  $(".tooltip-item").mouseout(function(){

    $(".tooltip-text").css("display", "none");

  });

});



  $(document).ready(function () {

    $("li#menu-item-66").mouseenter(function(){

      $("li#menu-item-66").addClass("green");

      $("li#menu-item-66 a").addClass("green");

      $(".sub-menu-wrapper").slideDown(); 

    });

    $(".sub-menu-wrapper").mouseleave(function(){

      $("li#menu-item-66").removeClass("green");

      $("li#menu-item-66 a").removeClass("green");

      $(".sub-menu-wrapper").slideUp(); 

    });

  });



  $(document).ready(function () {

    $("li#menu-item-289, li#menu-item-343, li#menu-item-288, li#menu-item-220").mouseenter(function(){

      $("li#menu-item-66").removeClass("green");

      $("li#menu-item-66 a").removeClass("green");

      $(".sub-menu-wrapper").slideUp(); 

    });

  });


  $(document).ready(function () {

    $("li#menu-item-505, li#menu-item-506, li#menu-item-507, li#menu-item-509").mouseenter(function(){

      $("li#menu-item-508").removeClass("green");

      $("li#menu-item-508 a").removeClass("green");

      $(".sub-menu-wrapper").slideUp(); 

    });

  });

  

  $(document).ready(function () {

    $("li#menu-item-508").mouseenter(function(){

      $("li#menu-item-508").addClass("green");

      $("li#menu-item-508 a").addClass("green");

      $(".sub-menu-wrapper").slideDown(); 

    });

    $(".sub-menu-wrapper").mouseleave(function(){

      $("li#menu-item-508").removeClass("green");

      $("li#menu-item-508 a").removeClass("green");

      $(".sub-menu-wrapper").slideUp(); 

    });

  });





  //Menu Mobile - Collapse

  $('.about-mob').on('click',function(){

    $('ul#menu-sobre-nos').toggleClass('collapse-mobile');
    $('ul#menu-about-us-footer-en').toggleClass('collapse-mobile');
    $('#footer-block-1').toggleClass('click');

  })



  $('.services-mob').on('click',function(){

    $('ul#menu-servicos').toggleClass('collapse-mobile');
    $('ul#menu-services-footer-en').toggleClass('collapse-mobile');
    
    $('#footer-block-2').toggleClass('click');

  })



  $('.references-mob').on('click',function(){
    $('ul#menu-obras-referencia').toggleClass('collapse-mobile');
    $('ul#menu-reference-works').toggleClass('collapse-mobile');
  })





  var serviceSwiper;



  $('.swiper-service-container').each(function(){

    var swiperID = $(this).attr('id');

    var paginationClass = $(this).attr('data-slug');

    var ident = $(this).attr('data-ident');

    serviceSwiper = new Swiper('#'+swiperID , {
      autoplay: {
        delay: 5000,
      },
      noSwiping: false,
      slidesPerView: 1,
      speed: 800,
      disableOnInteraction: false,
      loop: false,
      breakpoints: {
        768: {
          noSwiping: true,
        },
      },
      navigation: {
        nextEl: '#details-'+ident+' .swiper-service-next',
        prevEl: '#details-'+ident +' .swiper-service-prev',
      },
      pagination: {
        clickable: true,
        el: '.'+paginationClass,
      },    
      
      on : {
        slideChangeTransitionStart: function () {
          //resetCounter(swiperID);
          /* $('.service-slide-link').removeClass('active');
          enableActiveItem(); */
        },
        slideChangeTransitionEnd: function () {        
        },
        init: function () {       
          //resetCounter(swiperID);
        }
      }
    });
  })

  /* enableActiveItem();

  function resetCounter(swiperVar){
    $('.slider-control-progress-inner.'+swiperVar ).removeClass('active');
    setTimeout(function() {
      $('.slider-control-progress-inner.'+swiperVar ).addClass('active');
    }, 10);
  } */



  /* function enableActiveItem(){
    $('.service-block .swiper-slide-active').each(function(){
      var activeSlide = $(this).attr('id');      
      $('.service-slide-link[data-id="'+activeSlide+'"]').addClass('active');
    })
  } */

  $('.service-slide-link a').click(function(){
    
    var swiperDest = $(this).attr('data-swiper');
    var clickeElem = $(this).closest('.service-details').attr('id');
    $('.service-slide-link').removeClass('active');
    $(this).closest('.service-slide-link').addClass('active');
    var paginationClass = $('#'+swiperDest).attr('data-slug');
    serviceSwiper.destroy();
    serviceSwiper = new Swiper('#'+swiperDest , {
      autoplay: {
        delay: 5000,
      },
      disableOnInteraction: false,
      noSwiping: true,
      slidesPerView: 1,
      speed: 800,
      loop: false,
      breakpoints: {
        768: {
          noSwiping: true,
        },
      },
      navigation: {
        nextEl: clickeElem+' .swiper-service-next',
        prevEl: clickeElem+' .swiper-service-prev',
      },
      pagination: {
        clickable: true,
        el: '.'+paginationClass,
      },    
      
      on : {
        slideChangeTransitionStart: function () {
          //resetCounter(swiperDest);
          /* $('.service-slide-link').removeClass('active');
          enableActiveItem(); */
        },
        slideChangeTransitionEnd: function () {        
        }
      }
    });


    var destination = $(this).attr('data-id');

    serviceSwiper.slideTo( $('#'+destination).index(),200,false );

    //resetCounter(swiperDest);

  })



  

  



})(jQuery); // Fully reference jQuery after this point.

